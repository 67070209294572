<template>
  <div class="nav">
				<img id="toolbox" height="58" src="img/toolbox.png" alt="Hamwic Toolbox" />
    <img id="HET" src="img/HET-Horizontal-Large-300x144.png" alt="Hamwic Education Trust" @click="handleClose" />
    <div class="tooltitle">
      {{ title }}
      <div class="subtext" v-html="subtext"></div>
    </div>
    <div class="explain">
      <span v-html="explanation"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Toolbox',
    },
    subtext: {
      type: String,
      default: '',
    },
    explanation: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClose() {
      // Emit the 'close' event when the image is clicked
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
#toolbox {
position: absolute;
display: none;
right: 7em;
padding-bottom: 0.5em;
}

.nav {
  padding-top: 0.2vh;
  padding-bottom: 2vh;
  padding-left: 0;
  margin-left: -1vw;
  margin-right: -10vw;
  border-bottom: 0.8vh solid #8F7212;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #f0f0f0;
  z-index: 50;
  box-shadow: 0 1vh 0.5vh 0 rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.75s forwards;
    }
	
.nav img {
width: 10vw;
height: auto;

}

.nav img:hover {
transition: transform 0.5s ease;
  transform: scale(1.1);
}

	
@keyframes fadeIn {
  0% {
    transform: translateY(-300%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.tooltitle {
  color: #bca260;
  font-family: 'AvenirLTStd-Roman';
  display: inline;
  font-size: 4.5vh;
  font-weight: 500;
  position: relative;
  cursor: default;
}
.subtext {
  color: #848484;
  font-size: 2.5vh;
  margin-left: 0.5vw;
  font-style: italic;
  text-align: right;
  opacity: 1;
}
.explain {
  opacity: 0;
  margin-left: 5vw;
  width: 45vw;
  font-size: 2.5vh;
  font-family: 'AvenirLTStd-Roman';
  color: #848484;
  cursor: default;
  transition: opacity 0.75s ease;
  text-justify: inter-character;
}
    @media (max-width: 1024px) {
      .nav {
        max-height: 8vh;
        padding-top: 2vh;
        align-items: center;
		position: fixed;
        width: 110vw;
      }
      
      #HET {
        margin-left: 2em;
      } 
      .explain { 
       display: none;
      }
      
      .nav:hover .explain { 
        display: none;
      }
      #toolbox {
      display: none;
      }
    }
</style>

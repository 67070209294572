<template>
  <div class="grid-container">
    <div class="tool-grid">
      <div 
        class="tool-grid-item" 
        v-for="tool in placeholderTools" 
        :key="tool.id"
		@click="handleToolClick(tool.id)"
      >
        <img :src="tool.logo" :alt="tool.name" />
        <div class="tool-name">{{ tool.name }}</div><div class="tool-subname">{{ tool.subname }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      placeholderTools: [
        { id: 1, name: 'Babble', subname: 'Trust Networking', logo: 'https://via.placeholder.com/100' },
        { id: 2, name: 'Courier', subname: 'SharePoint Manager', logo: 'https://via.placeholder.com/100' },
        { id: 3, name: 'Signet', subname: 'Signature Generator', logo: 'img/sig.png' },
        { id: 4, name: 'Tesserarius', subname: 'Password Manager', logo: 'img/tess.png' },
      ],
    };
  },
  methods: {
    selectTool(toolId) {
    this.$emit('toolSelected', toolId); // Emit the selected tool id
    },
    handleToolClick(toolId) {
	if (toolId === 1) {
      this.showBabble();
    } else if (toolId === 2) {
      this.showSharePoint();
    } else if (toolId === 3) {
      this.showSignatureGenerator();
    } else if (toolId === 4) {
      this.showPasswordManager();
    } else {
      this.selectTool(toolId);
    }
  },
  
	showBabble() {
  this.$emit('toolSelected', 1);
  this.$emit('updateContent', 
    'Babble', 
    'Trust Networking', 
    'Communicate with schools in the Hamwic Education Trust. Seek advice on teaching methods or ideas. Post something you are proud of. See how other Hamwic schools are doing things. Find out if schools are getting rd of something you might be able to use.',
  );
},

	showSharePoint() {
  this.$emit('toolSelected', 2);
  this.$emit('updateContent', 
    'Courier', 
    'SharePoint Manager', 
    'Easily manage shortcuts to specific SharePoint folders in your OneDrive.',
  );
},

	showSignatureGenerator() {
  this.$emit('toolSelected', 3);
  this.$emit('updateContent', 
    'Signet', 
    'Signature Generator', 
    'An early form of signature or seal.  The wearing of <b>signet</b> rings (from the Latin "<i>signum</i>" meaning "<i>sign</i>" or "<i>mark</i>") dates as far back as ancient Egypt. <br /> The seal of the Pharaoh is mentioned in the <i>Book of Genesis</i>.',
  );
},
    showPasswordManager() {
  this.$emit('toolSelected', 4); // Emit 4 for Password Manager
  this.$emit('updateContent', 
    'Tesserarius', 
    'Password Manager', 
    'The <strong>Tesserarius</strong> was the Roman army officer responsible for guarding watchwords, ensuring their secure distribution to soldiers and strict adherence to security protocols that prevented unauthorized access to military information.',
  );
},

  },
};
</script>

<style scoped>
.grid-container {
  padding: 0 2vw;
}

.tool-grid {
  padding-top: 2vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(2vh, 1fr));
  gap: 3vw;
  animation: fadeIn 1.4s forwards;
}

.tool-grid-item {
  text-align: center;
  cursor: pointer;
  user-select: none;
  box-shadow: 0.2vw 0.2vw 0.2vw 0.2vw rgba(0.2, 0.2, 0.2, 0.2);
  padding-top: 1vh;
  padding-bottom: 1vh;
  background-color: #f0f0f0;
}

.tool-grid-item img {
  padding-top: 1vh;
  width: 30%;
  height: auto;
  user-select: none;
  pointer-events: none;
  transition: transform 0.5s ease;
  transform: translateY(15%);
}

.tool-grid-item:hover img {
  transform: scale(1.1);
  transition: transform 0.7s ease;
}

.tool-grid-item:active {
box-shadow: inset  0.2vw 0.2vw 0.2vw 0.2vw rgba(0.2, 0.2, 0.2, 0.2);
}

.tool-name {
  padding-top: 1.5vh;
  opacity: 0;
  transition: opacity 0.5s ease;
  text-align: center;
  color: #848484;
  font-size: 3.5vh;
}

.tool-subname {
  padding-top: 0.3vh;
  opacity: 0;
  transition: opacity 0.5s ease;
  text-align: center;
  color: #ADADAD;
  font-size: 2.5vh;
  font-style: italic;
}

.tool-grid-item:hover .tool-name{
  opacity: 1;
}
.tool-grid-item:hover .tool-subname{
  opacity: 1;
  transition-delay: 0.2s;
}


@media (max-width: 1024px) {
	.tool-grid {
	padding-top: 12vh;
	padding-bottom: 6vh;
	}

  .tool-grid-item img {
  transform: scale(1.1);
  }
  .tool-grid-item .tool-name {
  opacity: 1;
  }
  .tool-grid-item .tool-subname {
  opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  60% {
    transform: translateY(2%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>

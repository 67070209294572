<template>
  <div id="app">
    <!-- Authentication Waiting Screen -->
    <div v-if="!isAuthenticated" class="noauth">
      <img class="padlock" src="img/padlock.svg" alt="Padlock" />
      <h2>Waiting for authentication...</h2>
    </div>

    <!-- Loading Screen -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-dialog">
        <span class="loading-wave"><p>Loading...</p></span>
      </div>
    </div>

    <!-- SharePoint Management Tool -->
    <div v-if="isAuthenticated">
	<h3>SharePoint Explorer</h3>
	<div id="SharePointExplorer">
	<button v-if="canGoBack" @click="navigateBack" class="back-button">⬅ Back</button>
      <!-- User's Groups -->
      <section v-if="driveItems.length === 0 && !isFolderEmpty">
        <ul>
          <li v-for="group in groups" :key="group.id" class="group-item">
            <span @click="fetchGroupDriveItems(group.id)" class="folder-item">📂 {{ group.displayName }}</span>
          </li>
        </ul>
      </section>

      <!-- Drive Items -->
      <section v-if="driveItems.length > 0">
        <ul class="drive-items-list">
          <li v-for="item in driveItems" :key="item.id" class="drive-item">
            <div class="drive-item-info">
              <span v-if="item.folder" @click="fetchDriveItems(item.id)" class="folder-item">
                📂 {{ item.name }}
              </span>
              <span v-else class="file-item">📄 {{ item.name }}</span>
            </div>
            <div class="action-btn">
              <button @click="addShortcut(item)">Add Shortcut</button>
            </div>
          </li>
        </ul>
      </section>
	<div v-if="isFolderEmpty" class="empty-folder-message">
	This folder is empty.
	</div>
	</div>
      <!-- Existing Shortcuts -->
	  <h3>Current Shortcuts</h3>
	  <div id="CurrentShortcuts">
      <section>
        <ul>
          <li v-for="shortcut in shortcuts" :key="shortcut.id">
            {{ shortcut.name }}
            <button @click="removeShortcut(shortcut.id)">Remove</button>
          </li>
        </ul>
      </section>
    </div>
	</div>
  </div>
</template>


<script>
import { getMsalInstance, loginRequestSP } from "../authConfig";

export default {
  data() {
    return {
      isAuthenticated: false,
      isLoading: false, // To manage loading state
      groups: [],  // Store user's groups
      driveItems: [],
      shortcuts: [],
      accessToken: null,
      currentDriveId: null, // Store the current drive ID for further requests
	  pathStack: [],
    };
  },
  computed: {
    canGoBack() {
      return this.pathStack.length > 0 || this.currentDriveId; // Show Back button if inside folders or a group
    },
  },
  mounted() {
	document.title = "Hamwic | Courier";
    this.triggerSignIn();
  },
  methods: {
    async triggerSignIn() {
      try {
        const msalInstance = getMsalInstance();
        const account = msalInstance.getActiveAccount();

        if (!account) {
          console.log("No active account, initiating login...");
          const loginResponse = await msalInstance.loginPopup(loginRequestSP);
          msalInstance.setActiveAccount(loginResponse.account);
        }

        if (!msalInstance.getActiveAccount()) {
          throw new Error("Login successful, but no active account set.");
        }

        this.isAuthenticated = true;
        this.accessToken = (await msalInstance.acquireTokenSilent(loginRequestSP)).accessToken;

        console.log("Authentication successful. Fetching data...");
        await this.fetchUserGroups();
        await this.fetchShortcuts();
      } catch (error) {
        console.error("Login failed or authentication issue:", error);
        this.isAuthenticated = false;
      }
    },

    // Fetch the authenticated user's groups
async fetchUserGroups() {
  this.setLoading(true);
  try {
    const response = await fetch("https://graph.microsoft.com/v1.0/me/memberOf", {
      headers: { Authorization: `Bearer ${this.accessToken}` },
    });

    const data = await response.json();

    // Filter groups that have resourceProvisioningOptions and include "Team"
    this.groups = data.value.filter(
      (group) =>
        group.resourceProvisioningOptions &&
        group.resourceProvisioningOptions.includes("Team")
    ) || [];

    console.log("Fetched groups:", this.groups);
  } catch (error) {
    console.error("Error fetching groups:", error);
  } finally {
    this.setLoading(false);
  }
},

async fetchGroupDriveItems(groupId, folderId = "root") {
  if (!groupId) {
    console.error("Group ID is required.");
    return;
  }

  this.setLoading(true);
  try {
    const driveResponse = await fetch(
      `https://graph.microsoft.com/v1.0/groups/${groupId}/drive`,
      {
        headers: { Authorization: `Bearer ${this.accessToken}` },
      }
    );

    if (!driveResponse.ok) {
      throw new Error(`Error fetching drive details: ${driveResponse.status}`);
    }

    const driveData = await driveResponse.json();
    this.currentDriveId = driveData.id; // Store the drive ID

    // Now fetch the items in the specified folder
    const itemsResponse = await fetch(
      `https://graph.microsoft.com/v1.0/drives/${this.currentDriveId}/items/${folderId}/children`,
      {
        headers: { Authorization: `Bearer ${this.accessToken}` },
      }
    );

    if (!itemsResponse.ok) {
      throw new Error(`Error fetching drive items: ${itemsResponse.status}`);
    }

    const data = await itemsResponse.json();
    this.driveItems = data.value || [];
    console.log("Fetched drive items:", this.driveItems);
	
	
  } catch (error) {
    console.error("Error fetching drive items:", error.message);
  } finally {
    this.setLoading(false);
  }
},

async fetchDriveItems(folderId = "root") {
  this.setLoading(true);
  try {
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/drives/${this.currentDriveId}/items/${folderId}/children`,
      {
        headers: { Authorization: `Bearer ${this.accessToken}` },
      }
    );

    if (!response.ok) {
      throw new Error(`Error fetching drive items: ${response.status}`);
    }

    const data = await response.json();

    // Check if the folder is empty
    if (data.value && data.value.length === 0) {
      console.log("Folder is empty:", folderId);
      this.driveItems = [];
      this.isFolderEmpty = true; // Set a flag for UI handling
    } else {
      this.driveItems = data.value || [];
      this.isFolderEmpty = false; // Reset the flag if folder has items
    }

    console.log("Fetched drive items for folder:", folderId, this.driveItems);

    // Push the folderId to pathStack for navigation
    if (!this.pathStack.includes(folderId)) {
      this.pathStack.push(folderId);
    }
  } catch (error) {
    console.error("Error fetching drive items:", error.message);
  } finally {
    this.setLoading(false);
  }
},


navigateBack() {
  if (this.pathStack.length > 1) {
    // Pop the current folder ID from the stack
    this.pathStack.pop();
    // Peek the previous folder ID
    const previousFolderId = this.pathStack[this.pathStack.length - 1];
    console.log("Navigating back to folder:", previousFolderId);

    // Fetch the contents of the parent folder
    this.fetchDriveItems(previousFolderId);
  } else if (this.pathStack.length === 1) {
    // At the root of the drive, return to group selection
    console.log("Returning to group selection...");

    this.currentDriveId = null;
    this.driveItems = [];
    this.pathStack = []; // Clear the stack for a fresh start
  } else {
    // At the group selection level (this is essentially a fallback, just in case)
    console.log("Already at the group selection level.");
  }
},



async fetchShortcuts() {
  this.setLoading(true);
  try {
    const query = "?select=id,name,remoteItem,webUrl"; // Specify only fields we care about
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/me/drive/root/search(q='')${query}`,
      {
        headers: { Authorization: `Bearer ${this.accessToken}` },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch OneDrive shortcuts using search API");
    }

    const data = await response.json();

    // Filter items that have the `remoteItem` property
    this.shortcuts = data.value.filter(item => item.remoteItem);

    console.log("Fetched all OneDrive shortcuts:", this.shortcuts);
  } catch (error) {
    console.error("Error fetching shortcuts:", error);
  } finally {
    this.setLoading(false);
  }
},




    async addShortcut(item) {
      try {
        const response = await fetch("https://graph.microsoft.com/v1.0/me/drive/root/children", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: item.name || "Shortcut",
            remoteItem: {
              id: item.id,
              parentReference: { driveId: this.currentDriveId },
            },
          }),
        });

        const data = await response.json();
        this.shortcuts.push(data);
        console.log("Shortcut added:", data);
      } catch (error) {
        console.error("Error adding shortcut:", error);
      }
    },

    async removeShortcut(itemId) {
      try {
        await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${itemId}`, {
          method: "DELETE",
          headers: { Authorization: `Bearer ${this.accessToken}` },
        });

        this.shortcuts = this.shortcuts.filter((shortcut) => shortcut.id !== itemId);
        console.log("Shortcut removed:", itemId);
      } catch (error) {
        console.error("Error removing shortcut:", error);
      }
    },

    // Helper method to toggle loading state
    setLoading(state) {
      this.isLoading = state;
    },
  },
};
</script>

<style scoped>
body {
overflow: hidden;
}


#app {
  max-width: 83%;
  padding: 3vh;
  font-family: 'Onest', sans-serif;
  font-weight: 200;
}

#SharePointExplorer {
max-height: 35vh;
overflow-y: auto;
overflow-x: hidden;
}

#CurrentShortcuts {
max-height: 22vh;
overflow-y: auto;
overflow-x: hidden;
}

.noauth {
  display: flex;
  align-items: center;
  }

.noauth .padlock {
  display: inline-block;
  animation: padlockshake 2.5s ease-in-out infinite;
  transform-origin: 50% 100% 30%;
  width: 4em;
  height: auto;
  padding: 2em;
  pointer-events: none;
}

.padlock {
  width: 4em;
  height: auto;
  animation: padlockshake 2.5s ease-in-out infinite;
  transform-origin: 50% 100% 30%;
}

@keyframes padlockshake {
  0% {
        transform: rotate(0deg);
    }
60% {
        transform: rotate(0deg);
    }
 65% {
        transform: rotate(-5deg);
    }
    70% {
        transform: rotate(5deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    80% {
        transform: rotate(5deg);
    }
 85% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/* Loading Screen */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.loading-dialog {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.loading-wave {
  display: inline-block;
  animation: wave 0.75s infinite ease;
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

/* SharePoint and Drive Items */
.group-item {
  padding: 5px;
  font-size: 16px;
  transition: background-color 0.2s ease;
}

.group-item:hover {
  background-color: #f9f9f9;
}

/* Drive Items List */
.drive-items-list {
  list-style: none;
  padding: 0;
}

.drive-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  transition: background-color 0.2s ease;
}

.drive-item:hover {
  background-color: #f9f9f9;
}

.drive-item-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.folder-item,
.file-item {
  font-size: 16px;
}

.folder-item {
  cursor: pointer;
  transition: color 0.3s ease;
}

.folder-item:hover {
  color: #0078d4;
}

.file-item {
  color: #333;
}

.empty-folder-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

/* Action Button (Add Shortcut) */
.action-btn {
  margin-left: 20px;
}

.action-btn button {
  padding: 5px 10px;
  background-color: #bca260;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.action-btn button:hover {
  background-color: #8F7212;
}

/* Current Shortcuts Section */
section ul {
  padding: 0;
  list-style: none;
}

section li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

section button {
  margin-left: 20px;
  padding: 5px 10px;
  background-color: #ff4c4c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

section button:hover {
  background-color: #e63e3e;
}

.back-button {
  margin: 1px 0;
  padding: 2px 5px;
  background-color: #bca260;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #8F7212;
}


</style>

import { PublicClientApplication } from '@azure/msal-browser';

let msalInstance;

export const initializeMsalInstance = async (authority = "common") => {
    const msalConfig = {
        auth: {
            clientId: 'eec24933-a8d7-49c7-8491-064058b9721b',
            authority: `https://login.microsoftonline.com/${authority}`,
            redirectUri: 'https://jwginge.com:443', 
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true, // Edge compatibility
        }
    };

    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
};

export const getMsalInstance = () => {
    if (!msalInstance) {
        throw new Error('MSAL instance is not initialized. Call initializeMsalInstance first.');
    }
    return msalInstance;
};

export const loginRequest = {
    scopes: [
        "Group.Read.All",
        "User.ReadBasic.All",
        "GroupMember.Read.All",
    ]
};

export const loginRequestSP = {
    scopes: [
        "Sites.FullControl.All",
		"Sites.Manage.All",
        "User.ReadBasic.All",
        "Files.ReadWrite.All",
    ]
};

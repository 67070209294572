import { createApp } from 'vue';
import App from './App.vue';
import { initializeMsalInstance, getMsalInstance } from './authConfig';

const app = createApp(App);

// Initialize MSAL instance
initializeMsalInstance('common').then(() => {
    // Call getMsalInstance directly
    const msalInstance = getMsalInstance();

    // You can check if the user is already signed in here if necessary
    // For example, check if there are accounts available
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        // User is signed in, do something (e.g., redirect or load user data)
    }

    app.mount('#app');
}).catch(error => {
    console.error('MSAL Initialization Error:', error);
});

<template>
  <div
    id="babble"
    class="babble-container"
    @mousedown="onMouseDown"
    @mousemove="onMouseMove"
    @mouseup="onMouseUp"
    @mouseleave="onMouseUp"
  >
    <div class="grid" :style="gridStyle">
  <!-- Horizontal grid lines -->
  <div
    v-for="y in visibleHorizontalLines"
    :key="`h-${y}`"
    class="line horizontal"
    :style="getLineStyle(y, 'horizontal')"
  ></div>

  <!-- Vertical grid lines -->
  <div
    v-for="x in visibleVerticalLines"
    :key="`v-${x}`"
    class="line vertical"
    :style="getLineStyle(x, 'vertical')"
  ></div>

      
  
      <div
        v-for="school in schools"
        :key="`line-${school.id}`"
        class="connection-line"
        :style="getConnectionLineStyle(school)"
      ></div>





      <!-- Schools -->
      <div
        v-for="(school, index) in schools"
        :key="`school-${index}`"
        class="school"
        :style="getSchoolStyle(school)"
      >
<model-viewer
  src="/img/models/castle/castle.glb"
  alt="3D School Building"
  shadow-intensity="1"
  reveal="auto"
  loading="lazy"
  @load="onModelLoad"
  :id="`castle-${index}`"
  :style="{
    width: `${200 * school.randomScale.x}px`,
    height: `${200 * school.randomScale.y}px`
  }"
/>





        
        <!-- School Name mark?? -->
        <div class="school-name" :style="getNameStyle(school)">
        {{ school.name }}
	<img 
    v-if="school.logo"
    :src="school.logo" 
    :alt="school.name"
	class="school-logo"
	:style="{
    maxWidth: `${school.randomScale.x * 4}vw`,
    transform: `translateY(${school.randomScale.y * 15}vh)`
    }"
    />
        </div>  
      </div>
    </div>
  </div>
</template>



<script>
import '@google/model-viewer';

export default {
  name: "Babble",
  data() {
  return {
  partnerships: [
        { id: 1, name: "Hillary Partnership", randomColor: "#202e8a", logo: "/img/hiltrust.png" },
        { id: 2, name: "Greenway Partnership", randomColor: "#aac033", logo: "/img/greenway.png" },
		{ id: 3, name: "Mayflower Partnership", randomColor: "#8dc455", logo: "/img/mayflower.png" },
		{ id: 4, name: "Ridings Partnership", randomColor: "#dccf9a", logo: "/img/ridings.png" },
		{ id: 5, name: "Edwin Jones Partnership", randomColor: "#7b1870", logo: "/img/edwin.png" },
		{ id: 6, name: "Jefferys Education Partnership", randomColor: "#09447e", logo: "/img/jep.png" },
      ],
    gridSpacing: 100,
    gridSize: 35, // You can adjust grid size
    perspective: 1700,
    offsetX: 0,
    offsetY: 0,
    isDragging: false,
    lastMousePos: { x: 0, y: 0 },
    schools: [],
    maxDistance: 5000, // Maximum spread distance for schools on each axis
    placedSchools: [],
    hamwicPosition: { x: 0, y: 0 }, // Track Hamwic's position
  };
},
  computed: {
  visibleHorizontalLines() {
    const visibleLines = Math.ceil(window.innerHeight / this.gridSpacing) + 200; // Extra padding
    return Array.from({ length: visibleLines }, (_, i) => i - Math.floor(visibleLines / 2)); // Center lines
  },
  visibleVerticalLines() {
    const visibleLines = Math.ceil(window.innerWidth / this.gridSpacing) + 200; // Extra padding
    return Array.from({ length: visibleLines }, (_, i) => i - Math.floor(visibleLines / 2)); // Center lines
  },
    gridStyle() {
    return {
      transform: `perspective(${this.perspective}px) rotateX(60deg) translateZ(-180vh) translate(${this.offsetX}px, ${this.offsetY}px)`,
      position: "relative",
      width: "1500vw", // Limit grid width to the viewport width
      height: "1000vh", // Limit grid height to the viewport height
      transformStyle: "preserve-3d",
    };
	},

  },
  methods: {
  
  getConnectionLineStyle(school) {
  // Check if the school is a partner
  if (!school.isPartner) {
    return {}; // Return an empty object if it's not a partner, meaning no line will be drawn
  }

  // Get scaling factors for Hamwic and the current partner school
  const hamwicScale = this.schools.find(s => s.id === '74a8e3d1-33f1-4cbd-9fef-39048c690e09').randomScale || { x: 1, y: 1 };
  const partnerScale = school.randomScale || { x: 1, y: 1 };

  // Adjust the starting and ending positions to account for scaling
  const startX = this.hamwicPosition.x + (hamwicScale.x * 103); // Adjust Hamwic's position with scaling
  const startY = this.hamwicPosition.y + (hamwicScale.y * 100); // Adjust Hamwic's position with scaling
  const endX = school.cellX + (partnerScale.x * 100); // Adjust partner's position with scaling
  const endY = school.cellY + (partnerScale.y * 100); // Adjust partner's position with scaling

  // Calculate the distance and angle for the line
  const distance = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2));
  const angle = Math.atan2(endY - startY, endX - startX);

  // Define dash pattern (length of dashes and gaps)
  const dashLength = 300; // Length of the dash
  const dashGap = 120; // Gap between dashes
  const totalLength = dashLength + dashGap;

  const zOffset = -20;

  return {
    position: 'absolute',
    top: `${startY}px`,
    left: `${startX}px`,
    width: `${distance}px`,
    height: '1.2vh',  // Line thickness
    background: `repeating-linear-gradient(to right, #8F7212, #8F7212 ${dashLength}px, transparent ${dashLength}px, transparent ${totalLength}px)`, // Dashed pattern
    transform: `rotate(${angle}rad) translateZ(${zOffset}px)`,
    transformOrigin: '0 0',  // Ensure the rotation is based on the starting point
    zIndex: 1,  // Set it behind the schools
  };
},

  
async loadSchools() {
  try {
    const timestamp = new Date().getTime();
    const response = await fetch(`schools.list?v=${timestamp}`);
    if (!response.ok) {
      throw new Error("Failed to load schools list");
    }
    const schoolsData = await response.json();

    const radius = this.gridSpacing * 25; // Base radius for partner placement
    const placedSchools = [];

    // Find Hamwic and place it at the center
    const hamwicSchool = schoolsData.find(school => school.id === '74a8e3d1-33f1-4cbd-9fef-39048c690e09');
    if (hamwicSchool) {
      placedSchools.push({
        ...hamwicSchool,
        cellX: 0,
        cellY: 0,
        randomScale: { x: 2.2, y: 2.2, z: 2.2 }, // Hamwic is larger
        randomColor: '#8F7212', // Hamwic color
      });
    } else {
      console.error("Hamwic school not found in data");
      return;
    }

    // Group schools by their partner ID
    const partners = this.partnerships.map(partner => ({
      ...partner,
      schools: schoolsData.filter(school => school.partner === partner.id)
    }));

    // Place partners in a circle around Hamwic
    const partnerPositions = this.calculateCirclePositions(radius, partners.length);
    partners.forEach((partner, index) => {
      const { x: partnerX, y: partnerY } = partnerPositions[index];
      const partnerColor = partner.randomColor || '#12788F';
      placedSchools.push({
      ...partner,
        cellX: partnerX,
        cellY: partnerY,
		isPartner: true,
        randomScale: { x: 1.7, y: 1.7, z: 1.7 }, // Partners are slightly larger
        randomColor: partnerColor  // Partner color
      });

      // Place partner schools in a smaller circle around the partner
      const schoolRadius = radius / 3.5; 
      const schoolPositions = this.calculateCirclePositions(schoolRadius, partner.schools.length);
      partner.schools.forEach((school, schoolIndex) => {
        const { x: schoolX, y: schoolY } = schoolPositions[schoolIndex];
		const schoolColor = school.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        placedSchools.push({
          ...school,
          cellX: partnerX + schoolX,
          cellY: partnerY + schoolY,
          randomScale: { x: 1.1, y: 1.1, z: 1.1 }, // Normal scale
          randomColor: schoolColor, // Random color
          logo: school.logo
       });
     });
    });

    // Update schools with new positions
    this.schools = placedSchools;

    // Adjust grid offset to center Hamwic
    this.offsetX = 430;
    this.offsetY = 1200;

  } catch (error) {
    console.error("Error loading schools list:", error);
  }
},



calculateCirclePositions(radius, count) {
  const angleStep = (2 * Math.PI) / count;
  const startingAngle = Math.PI * 2; //which radian do we start at

  return Array.from({ length: count }, (_, i) => ({
    x: Math.cos(startingAngle + i * angleStep) * radius,
    y: Math.sin(startingAngle + i * angleStep) * radius
  }));
},








 getLineStyle(index, type) {
    const halfViewportWidth = window.innerWidth / 2;
    const halfViewportHeight = window.innerHeight / 2;

    // Horizontal lines
    if (type === "horizontal") {
      // Position horizontal lines relative to the center of the screen
      const position = (index * this.gridSpacing) - halfViewportHeight;

      return {
        transform: `translate3d(-900vw, ${position}px, 0)`, // Translate Y position for horizontal lines
        width: "9000vw", // Full viewport width
        height: "0.6vh", // Thin line
        backgroundColor: "rgba(0, 0, 0, 0.3)", // Grid line color
        position: "absolute",
        zIndex: 0, // Ensure grid lines are behind everything else
      };
    } else { // Vertical lines
      // Position vertical lines relative to the center of the screen
      const position = (index * this.gridSpacing) - halfViewportWidth;

      return {
        transform: `translate3d(${position}px, -900vw, 0)`, // Translate X position for vertical lines
        height: "9000vh", // Full viewport height
        width: "0.5vh", // Thin line
        backgroundColor: "rgba(0, 0, 0, 0.3)", // Grid line color
        position: "absolute",
        zIndex: 0, // Ensure grid lines are behind everything else
      };
    }
  },













    getSchoolStyle(school) {
  const zOffset = 78; // Raise the castles above the grid
  return {
    transform: `translate3d(${school.cellX}px, ${school.cellY}px, ${zOffset}px) 
                rotateX(-70deg)`,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 15, // Ensure schools are above the grid
  };
},



onModelLoad(event) {
    const modelViewer = event.target;

    // Use the school's random color
    const schoolIndex = modelViewer.id.split('-')[1];
    const school = this.schools[schoolIndex];
    const color = school.randomColor;

    // Access the model's material
    const model = modelViewer.model;
    if (model) {
      const materials = model.materials || [];
      materials.forEach((material) => {
        material.pbrMetallicRoughness.setBaseColorFactor(this.hexToRgb(color));
      });
    }
  },
  // Utility function to convert HEX to [r, g, b, a]
  hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r / 255, g / 255, b / 255, 1.0]; // Normalize to [0, 1]
  },


    getNameStyle(school) {
	const schoolScale = school.randomScale || { x: 1, y: 1 };
    const translateYValue = -36 * schoolScale.y + 'vh'; 	
      return {
        fontSize: "4vh",
        fontWeight: "bold",
        textAlign: "center",
        padding: "1vw",
        backgroundColor: "fffff",
        borderRadius: "1vw",
        color: "black",
        whiteSpace: "nowrap",
        transform: `translateY(${translateYValue})`,
        zIndex: 10,
      };
    },

    onMouseDown(event) {
      this.isDragging = true;
      this.lastMousePos = { x: event.clientX, y: event.clientY };
      this.$el.requestPointerLock();
      document.body.style.cursor = "grabbing";
    },
    onMouseMove(event) {
      if (this.isDragging) {
        const deltaX = event.movementX;
        const deltaY = event.movementY * 3;

        this.offsetX += deltaX;
        this.offsetY += deltaY;
      }
    },
    onMouseUp() {
      this.isDragging = false;
      document.exitPointerLock();
      document.body.style.cursor = "auto";
    },
  },

  mounted() {
	document.title = "Hamwic | Babble";
    this.loadSchools();
  },
};
</script>

<style scoped>
/* Styles for babble container and grid */
.babble-container {
  position: fixed;
  width: 120vw;
  height: 110vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  cursor: auto;
  margin-left: -5vw;
}

.grid {
  position: relative;
display: flex;
flex-wrap: wrap;
  transform-style: preserve-3d;
}

.line {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
}

.school {
  position: absolute;
  transition: transform 0s ease-in-out;
  text-align: center;
  display: flex;
}

.school-name {
  font-weight: bold;
  color: black;
  white-space: nowrap;
  text-align: center;
  padding: 1vw;
  border-radius: 0px;
  z-index: 10;
}

.school-logo {
  display: block;
  margin: 0 auto;
  bottom: 100%;
  border-radius: 0px;
  background-color: transparent;
}


model-viewer::part(default-progress-bar) {
  display: none;
}

model-viewer::part(default-progress-mask) {
  display: none;
}

mark {
  background-color: #f0f0f0;
  color: black;
}

</style>

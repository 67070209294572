<template>
  <div ref="lottieContainer" :style="{ width: width, height: height }"></div>
</template>

<script>
import lottie from 'lottie-web';

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  mounted() {
 const animation = lottie.loadAnimation({
  container: this.$refs.lottieContainer,
  renderer: 'svg',
  loop: this.loop,
  autoplay: this.autoplay,
  path: this.src,
});

animation.addEventListener('enterFrame', (event) => {
  if (event.currentTime >= 190) {
    this.$emit('animationComplete');
    animation.removeEventListener('enterFrame'); // Remove listener after emitting
  }
});

  },
};
</script>

<style scoped>
/* Add any styling specific to this component */
</style>

<template>
<div>
  <div :class="['tippytop', { 'navscrolled': scrolled || loading, 'tippytop-comeback': scrolledmore }]" ref="tippytop">
</div>

    <div class="filterbar">
<input type="text" v-model="searchQuery" placeholder="Search for a student..." />
</div>
</div>

    <div v-if="loading" class="loading-overlay">
      <div class="loading-dialog">
        <span class="loading-wave"><p>Loading students...</p></span>
      </div>
    </div>

    <div class="student-grid">
      <div
        v-for="student in filteredStudents"
        :key="student.id"
        class="student-card"
      >
        <img :src="student.photo || defaultPhoto" alt="Student Photo" class="student-photo" @mouseenter="showFloatingCard(student)" @mouseleave="hideFloatingCard"/>
        <div class="student-info" @click="handleStudentClick(student)">
          <h3>{{ student.displayName }}</h3>
          <p>{{ formatUserPrincipalName(student.userPrincipalName) }}</p>
          <button v-if="canResetPassword" @click="resetStudentPassword(student.id)">Reset Password</button>
        </div>
      </div>

     
<div v-if="isFloatingCardVisible" :style="{ top: floatingCardY + 'px', left: floatingCardX + 'px' }" class="floating-card">
  <div class="floating-card-content">
    <div class="floating-card-text">
      <h3>{{ currentStudent.displayName }}</h3>
      <p>{{ currentStudent.userPrincipalName }}</p>
      <p>
        Classes: 
        <span v-if="loadingClasses"><span class="loading-wave">---</span></span> 
        <span v-else>{{ currentStudent.teams.join(', ') }}</span>
      </p>
    </div>
    <img :src="currentStudent.photo || defaultPhoto" alt="Student Photo" class="floating-card-photo" />
  </div>
</div>
</div>


<!-- Password Reset Dialog, needs tweaking -->
<div v-if="isModalVisible" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
        <h3>Reset Password</h3>
 <img :src="currentStudent.photo" alt="Student Photo" class="floating-card-photo" />
  <p><b>{{ currentStudent.displayName }}<br /></b>
{{ currentStudent.userPrincipalName }}</p>
        <p><input type="password" v-model="newPassword" placeholder="Enter new password..." /></p>
        <div class="buttons"><button @click="closeModal">Cancel</button>
<button @click="submitPasswordReset">Reset Password</button></div>
    </div>
</div>



</template>

<script>
import { getMsalInstance } from '../authConfig';

export default {
  data() {
    return {
      searchQuery: '',
      defaultPhoto: 'img/avatar.png',
      localStudents: [],
      loading: false,
      isFloatingCardVisible: false,
      floatingCardX: 0,
      floatingCardY: 0,
      currentStudent: {},
  isModalVisible: false,
      newPassword: '',
      passwordVisible: false,
  loadingClasses: false,
  scrolled: false,
  scrolledmore: false,
    };
  },
  props: {
    selectedSchoolId: {
      type: String,
      required: true
    },
    canResetPassword: {
      type: Boolean,
      required: true
    },
    schools: { 
      type: Array,
      required: true
    }
  },
  watch: {
    selectedSchoolId: 'fetchStudents',
  },
  computed: {
    filteredStudents() {
      if (this.searchQuery.trim() === '') {
        return this.localStudents; 
      }

      const queryLower = this.searchQuery.toLowerCase();
      return this.localStudents.filter(student => {
        const displayNameMatch = student.displayName.toLowerCase().includes(queryLower);
        const userPrincipalNameMatch = student.userPrincipalName.toLowerCase().includes(queryLower);
        return displayNameMatch || userPrincipalNameMatch;
      });
    }
  },
  methods: {
  
  goBack() {
window.location.reload()
},


    formatUserPrincipalName(userPrincipalName) {
      return userPrincipalName.split('@')[0];
    },
    
    async fetchStudents() {
  this.loading = true;
  let previousStudents = [...this.localStudents];
  const msalInstance = getMsalInstance();
  try {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw new Error("No active account found. Please sign in.");
    }

    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["Group.Read.All", "User.ReadBasic.All", "GroupMember.Read.All"],
      account: account,
    });

    const selectedSchool = this.schools.find(school => school.id === this.selectedSchoolId);
    
    if (!selectedSchool) {
      throw new Error("Selected school not found.");
    }

    let allStudents = [];
    let url = `https://graph.microsoft.com/v1.0/groups/${selectedSchool.studentGroupId}/members`;

    do {
      const studentListResponse = await fetch(url, {
        headers: {
          Authorization: `Bearer ${tokenResponse.accessToken}`,
        },
      });

      if (!studentListResponse.ok) {
        throw new Error(`Error fetching students: ${studentListResponse.statusText}`);
      }

      const studentsData = await studentListResponse.json();
      allStudents = allStudents.concat(studentsData.value);
      url = studentsData["@odata.nextLink"];
    } while (url);

    this.localStudents = await Promise.all(
      allStudents.map(async student => {
        try {
          const photoResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${student.id}/photo/$value`, {
            headers: {
              Authorization: `Bearer ${tokenResponse.accessToken}`,
            },
          });

          if (photoResponse.ok) {
            const photoBlob = await photoResponse.blob();
            student.photo = URL.createObjectURL(photoBlob);
          } else {
            student.photo = this.defaultPhoto;
          }

         
          student.teams = [];
        } catch (photoError) {
          console.error('Error fetching photo:', photoError);
          student.photo = this.defaultPhoto;
          student.teams = [];
        }
        return student;
      })
    );

    this.localStudents.sort((a, b) => {
      const nameA = a.displayName.toLowerCase();
      const nameB = b.displayName.toLowerCase();
      return nameA.localeCompare(nameB);
    });

  } catch (error) {
    console.error("Error fetching students:", error);
    this.localStudents = previousStudents;
  } finally {
    this.loading = false;
  }
},


async fetchStudentTeams(studentId) {
    const msalInstance = getMsalInstance();
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw new Error("No active account found. Please sign in.");
    }

    const authResponse = await msalInstance.acquireTokenSilent({
        scopes: ["eec24933-a8d7-49c7-8491-064058b9721b/.default"],
        account: account
    });

    const selectedSchoolId = this.selectedSchoolId;
    this.loadingClasses = true;
    try {
        const teamsResponse = await fetch(`https://jwginge.com:3000/api/student-teams/${studentId}?tenantId=${selectedSchoolId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${authResponse.accessToken}`
            }
        });

        if (teamsResponse.ok) {
            const teamsData = await teamsResponse.json();
            if (teamsData.value && teamsData.value.length > 0) {
                return teamsData.value.map(team => team.displayName);
            } else {
                return ['N/A'];
            }
        } else {
            throw new Error(`Error fetching teams: ${teamsResponse.statusText} (Status Code: ${teamsResponse.status})`);
        }
    } catch (error) {
        console.error("Error fetching teams for student:", error);
        return [];
    } finally {
        this.loadingClasses = false;
    }
},



async handleStudentClick(student) {
      // When a student card is clicked, set the currentStudent
      this.currentStudent = student;

      // Check if the photo has been fetched, if not, fetch it
      if (!student.photo) {
        const photo = await this.fetchStudentPhoto(student.id);
        this.$set(student, 'photo', photo);
        this.currentStudent.photo = photo; // Ensure photo is set
      }
    },


async showFloatingCard(student) {
  this.currentStudent = student;
  this.isFloatingCardVisible = true;
  this.updateFloatingCardPosition();
  this.currentStudent.teams = await this.fetchStudentTeams(student.id);
  
  document.addEventListener('mousemove', this.updateFloatingCardPosition);
},


    hideFloatingCard() {
      this.isFloatingCardVisible = false;
      document.removeEventListener('mousemove', this.updateFloatingCardPosition);
    },

updateFloatingCardPosition(event) {
  if (event) {
    const floatingCardWidth = 350;
    const floatingCardHeight = 100;
    
    let newX = event.clientX + 10;
    let newY = event.clientY + 10;

    if (newX + floatingCardWidth > window.innerWidth) {
      newX = window.innerWidth - floatingCardWidth - 100;
    }

    if (newX < 0) {
      newX = 10;
    }

    // Keep our card in the view window pls
    if (newY + floatingCardHeight > window.innerHeight) {
      newY = window.innerHeight - floatingCardHeight - 10;
    }

    if (newY < 0) {
      newY = 10;
    }

    // Update position state
    this.floatingCardX = newX;
    this.floatingCardY = newY;
  }
},


resetStudentPassword(studentId) {
    const student = this.localStudents.find(s => s.id === studentId);
    this.currentStudent = student;
    this.currentStudentId = studentId;
    this.isModalVisible = true;
},

togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
},






async submitPasswordReset() {
    try {
        // Get MSAL instance and acquire token for backend verification
        const msalInstance = getMsalInstance();
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw new Error("No active account found. Please sign in.");
        }

        const authResponse = await msalInstance.acquireTokenSilent({
            scopes: ["eec24933-a8d7-49c7-8491-064058b9721b/.default"],
            account: account
        });

        const response = await fetch('https://jwginge.com:3000/api/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authResponse.accessToken}`
            },
            body: JSON.stringify({
                tenantId: this.selectedSchoolId,
                studentId: this.currentStudentId,
                newPassword: this.newPassword,
				canResetPassword: this.canResetPassword,
            }),
        });

        const result = await response.json();
        if (result.success) {
            alert('Password reset successfully!');
        } else {
            alert(`Error resetting password: ${result.error}`);
        }
    } catch (error) {
        console.error('Error resetting password:', error);
        alert('An error occurred while resetting the password.');
    } finally {
        this.closeModal();
    }
},


closeModal() {
    this.isModalVisible = false;
    this.newPassword = '';
    this.passwordVisible = false;
},

handleScroll() {
      this.scrolled = window.scrollY > 32;
  this.scrolledmore = window.scrollY > 110;
    }
},

  
  mounted() {
    console.log("Fetching students for:", this.selectedSchoolId);
    this.fetchStudents();
window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>

.navscrolled {
 opacity: 0;
  transition: opacity 0.75s ease;
  z-index: 300;
}

.tippytop:not(.navscrolled) {
  opacity: 1;
  transition: opacity 0.75s ease;
  z-index: 0;
}

.student-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
  margin-left: 1em;
  margin-right: 11.5em;
}
.student-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  box-shadow: 0.1em 0.2em 0.1em 0.1em rgba(0.2, 0.2, 0.2, 0.2);
}

.student-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.student-info h3 {
  font-size: 16px;
  margin: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.student-info p {
  font-size: 12px;
  color: #666;
  margin: 2px 0;
}

.student-info button {
  margin-top: 10px;
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  background-color: #bca260;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.student-info button:hover {
  background-color: #8F7212;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.loading-dialog {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.floating-card {
  position: fixed;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
}

.floating-card-content {
  padding: 10px;
  display: flex;
  align-items: center;
}

.floating-card-text {
  flex: 1;
  margin-right: 10px;
}

.floating-card-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}
.filterbar {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  z-index: 0;
  margin-right: 4em;
}

.filterbar input {
  padding: 5px;
  font-size: 14px;
}

.back-arrow {
  position: relative;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  z-index: 0;
}

.back-arrow img {
  display: block;
  width: 50px;
  height: auto;
  transition: transform 0.5s ease;
  scale: 0.9;
}

.back-arrow::after {
  content: '';
  position: absolute;
  top: 110%;
  left: 0;
  width: 120%;
  height: 200%;
  background: linear-gradient(to top, rgba(240, 240, 240, 0.2), rgba(240, 240, 240, 0.4), rgba(240, 240, 240, 0.1));
  opacity: 0;
  transition: transform 0.75s ease, opacity 0.75s ease;
  z-index: 51;
}

.back-arrow:hover::after {
  transform: translateY(-105%);
  opacity: 1;
}

.back-arrow:hover img {
  transform: scale(1.1);
}


.tippytop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
  z-index: 0;
  position: sticky;
  top: 1.5%;
}

.tippytop-comeback {
  transition: opacity 0.75s ease;
  opacity: 1;
  z-index: 300;
}

.navscrolled:not(.tippytop-comeback) {
  transition: opacity 0.1s ease, z-index 1s ease, transform 0.75s ease;
  opacity: 0;
  z-index: 1;
  transform: translateY(-30em);
}




.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.buttons {
  display: flex; 
  justify-content: space-evenly;
}


.loading-wave {
  display: inline-block;
  animation: wave 0.75s infinite ease;
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-button {
  display: none;
}

body {
  -ms-overflow-style:none;
}

@media (max-width: 1024px) {
  .floating-card {
    opacity: 0;
  }
  tippytop {
    opacity: 0;
	}
  
  .tippytop-comeback {
    opacity: 0;
  }
  
  
  .student-grid {
  padding-top: 10vh;
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
}

.back-arrow {
  opacity: 0;
}


}

</style>

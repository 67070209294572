<template>
  <div id="app">
    <div v-if="!schoolSelected">
      <SchoolSelection :schools="schools" @schoolSelected="onSchoolSelected" />
    </div>
    <div v-else-if="!isAuthenticated">
<div class="noauth">
 <img class="padlock" src="img/padlock.svg" />
      <h2>Waiting for authentication...</h2>
    </div>
</div>
<div v-else-if="!canResetPassword">
<div class="authdeny">
  <img class="padlock" src="img/padlock.svg" />
      <h2><span class="denytext">You are not able to reset passwords for this school.</span></h2>
    </div>
</div>
    <div v-else>
     <StudentList ref="studentList" :selectedSchoolId="selectedSchoolId" :canResetPassword="canResetPassword" :schools="schools" @fetchStudents="fetchStudents" />
    </div>
  </div>
</template>

<script>
import SchoolSelection from './SchoolSelection.vue';
import StudentList from './StudentList.vue';
import { getMsalInstance, loginRequest } from '../authConfig';

export default {
  components: {
    SchoolSelection,
    StudentList,
  },
  
  props: {
  explanationContent: {
    type: String,
    default: '',
  },
  subtext: {  // New prop for subtext
    type: String,
    default: 'Password Manager',
  },
},

  
  data() {
    return {
      schools: [],
      schoolSelected: false,
      selectedSchoolId: null,
      canResetPassword: false,
      isAuthenticated: false,
    };
  },
  
  mounted() {
	document.title = "Hamwic | Tesserarius";
    this.loadSchools();
  },

  methods: {
  
  async loadSchools() {
  try {
    const timestamp = new Date().getTime(); // Generate timestamp to fight caching
    const response = await fetch(`schools.list?v=${timestamp}`);
    if (!response.ok) {
      throw new Error('Failed to load schools list');
    }
    const schoolsData = await response.json();
    this.schools = schoolsData;
  } catch (error) {
    console.error('Error loading schools list:', error);
  }
},

    onSchoolSelected(schoolId) {
      this.schoolSelected = true;
      this.selectedSchoolId = schoolId;
      this.triggerSignIn(schoolId);
    },

async triggerSignIn(schoolId) {
    try {
        const msalInstance = getMsalInstance();

        const authority = `https://login.microsoftonline.com/${schoolId}`;
        msalInstance.getConfiguration().auth.authority = authority;

        const popupPosition = {
            top: 0,
            left: (window.innerWidth * (2 / 3)) - 280
        };

        const loginResponse = await msalInstance.loginPopup({ ...loginRequest, popupWindowAttributes: { popupPosition } });
        msalInstance.setActiveAccount(loginResponse.account);
        

        this.canResetPassword = await this.checkPermissions();
this.isAuthenticated = true;
        if (this.canResetPassword) {
            this.$nextTick(() => {
                this.fetchStudents();
            });
        }
    } catch (error) {
        console.error('Login failed:', error);
    }
},
  

    async checkPermissions() {
const msalInstance = getMsalInstance();
      try {
        const account = msalInstance.getActiveAccount();
        if (!account) throw new Error("Please sign in.");

        const tokenResponse = await msalInstance.acquireTokenSilent({
          scopes: ["GroupMember.Read.All", "User.ReadBasic.All"],
          account: account,
        });

        const groupResponse = await fetch('https://graph.microsoft.com/v1.0/me/memberOf', {
          headers: {
            Authorization: `Bearer ${tokenResponse.accessToken}`,
          },
        });

        const groupData = await groupResponse.json();
        const selectedSchool = this.schools.find(school => school.id === this.selectedSchoolId);
        return groupData.value.some(group => group.id === selectedSchool.authorisedGroupId);
      } catch (error) {
        console.error("Error checking perms:", error);
        return false;
      }
    },

    async fetchStudents() {
      if (this.$refs.studentList) {
        await this.$refs.studentList.fetchStudents();
      } else {
        console.error('StudentList brokey');
      }
    },
  },
};
</script>

<style scoped>
#app {
  padding: 3vh;
  font-family: 'Onest', sans-serif;
  font-weight: 200;
}

.noauth:not(.authdeny) {
  display: flex;
  align-items: center;
  }
  
.authdeny:not(.noauth) {
  opacity: 1;
  display: flex;
  align-items: center;
  
  }
  
.denytext {
opacity: 0;
display: block;
transform: translateY(10em);
animation: animdenytext 4.5s ease forwards;
}

@keyframes animdenytext {
0% {
transform: translateY(0em);
}
60% {
opacity: 0;
}
100% {
opacity: 1;
transform: translateY(10em);
}
}

.authdeny .padlock {
  display: inline-block;
  width: 4em;
  height: auto;
  padding: 2em;
  pointer-events: none;
  animation: padlockauthdeny 3s ease forwards;
}

@keyframes padlockauthdeny {
    0% {
        transform: rotate(0deg);
    }
    80% {
transform:  translate(280%, 63%) scale(3);
    }
85% {
        transform: rotateX(-15deg) translate(280%, 60%) scale(3);
    }
    90% {
        transform: rotateX(15deg) translate(280%, 60%) scale(3);
    }
    95% {
        transform: rotateX(-15deg) translate(280%, 60%) scale(3);
}
100% {
transform:  rotateX(0deg) translate(280%, 60%) scale(3);
}
}

.noauth .padlock {
  display: inline-block;
  animation: padlockshake 2.5s ease-in-out infinite;
  transform-origin: 50% 100% 30%;
  width: 4em;
  height: auto;
  padding: 2em;
  pointer-events: none;
}

@keyframes padlockshake {
    0% {
        transform: rotate(0deg);
    }
60% {
        transform: rotate(0deg);
    }
 65% {
        transform: rotate(-5deg);
    }
    70% {
        transform: rotate(5deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    80% {
        transform: rotate(5deg);
    }
 85% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@media (max-width: 1024px) {
  .noauth{
  padding-top: 15vh;
}


}


</style>
